import request  from "../utils/request";
//获取报告类型
export const getReportType=data=>{
return request({
  method:'POST',
  url:'/system/report/getMold',
  data

})
}
//修改报告类型
export const editReportType=data=>{
  return request({
    method:'POST',
    url:'/system/report/updateMold',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }
  
  })
  }
  //获取并筛选报告列表
  

export const getReportList=data=>{
  return request({
    method:'POST',
    url:'/system/report/getReportList',
    data,
  })
  }
  //新增报告类型
  export const addReportType=data=>{
    return request({
      method:'POST',
      url:'/system/report/addMold',
      data,
      headers: {
        'CustomRequestType': 'application/json;charset=UTF-8'
      }
    
    })
    }
  //新增报告
  
  export const addReport=data=>{
    return request({
      method:'POST',
      url:'/system/report/addReport',
      data,
      headers: {
        'CustomRequestType': 'application/json;charset=UTF-8'
      }
    
    })
    }
    //编辑报告
   
    export const editReport=data=>{
      return request({
        method:'POST',
        url:'/system/report/updateReport',
        data,
        headers: {
          'CustomRequestType': 'application/json;charset=UTF-8'
        }
      
      })
      }
      //删除报告 
      export const delReport=data=>{
        return request({
          method:'POST',
          url:'/system/report/deleteReport',
          data,
        })
        }

        //上传文件
        export const uploadReport=data=>{
          return request({
            method:'POST',
            url:'/system/file/uploadFiles',
            data,
            // headers: {
            //   'Content-Type':'multipart/form-data'

            // }
          })
          }
          
          //获取报告详情
          export const getReportById=data=>{
            return request({
              method:'POST',
              url:'/system/report/getReportDetailt',
              data,
              // headers: {
              //   'Content-Type':'multipart/form-data'
  
              // }
            })
            }
            //获取报告类型详情
            
            export const getTypeById=data=>{
              return request({
                method:'POST',
                url:'/system/report/getMoldDetail',
                data,
                // headers: {
                //   'Content-Type':'multipart/form-data'
    
                // }
              })
              }
              //删除报告类型
              
              export const delTypeById=data=>{
                return request({
                  method:'POST',
                  url:'/system/report/deleteMold',
                  data,
                  // headers: {
                  //   'Content-Type':'multipart/form-data'
      
                  // }
                })
                }