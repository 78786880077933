<template>
  <div>
          <!-- 头部位置 -->
  <el-row  class="header" type="flex" justify="start" align="middle">
    <el-col :span="3">/专利检测报告管理/报告类型设置</el-col>
  </el-row>
  <!-- 标签位置 -->
    <Label :policy="policy" @showDialog="showDialog" @refreshlist="refreshlist" @delType="delType"> </Label>
  <!-- 弹框 -->
  <el-dialog title="报告类型编辑"  :visible.sync="isShow" @close="closeDia">
  <!-- 表单 -->
   <el-form :model="newtitle" ref="editForm" :rules="rules" label-width="145px" >
  <el-form-item label="原报告类型名称:" >
    <el-input disabled v-model="title" class="disable" ></el-input>
  </el-form-item>
  <el-form-item label="原报告类型描述:" >
    <el-input disabled v-model="content" class="disable" ></el-input>
  </el-form-item>
  <el-form-item label="新报告类型名称:" prop="title"  >
    <el-input v-model="newtitle.title" ></el-input>
  </el-form-item>
    <el-form-item label="新报告类型描述:" prop="content"  >
    <el-input v-model="newtitle.content" ></el-input>
  </el-form-item>
  <!-- 确定和取消 -->
  <el-form-item class="subbtn">
    <el-button class="btnOK"  @click="btnok"
     >确认</el-button>
    <el-button @click="btnout">取消退出</el-button>
  </el-form-item>
</el-form>
  </el-dialog>
  
  </div>
</template>

<script>
import Label from './label.vue'
import {getReportType,editReportType,getTypeById,delTypeById} from '@/api/report.js'

export default {
components: {
  Label
},
data() {
  
  return {
    isShow:false,//控制第一个弹框的显示与否
      policy:[],
   
      title:'',
      content:'',
      newtitle:{
        content:'',
        title:'',
           id:'',
      },
      rules:{
        title:[ { required: true, message: '报告名称不能为空',trigger: 'blur' ,max:'10',min:'2'},],
        content:[{ required: true, message: '类型描述不能为空',trigger: 'blur' ,max:'10',min:'2'}]
      }
  }
},
created () {
    this.getReportType()
  
},
methods: {
     //标签类型获取
   async getReportType(){
    let {data:{obj}}= await getReportType()
    this.policy=obj
  },
  //弹窗的显示
 async showDialog(id){
   this.newtitle.id=id
   let {data}=await getTypeById({moldId:id}) 

   this.title=data.title
   this.content=data.content
   this.isShow=true

   },
   //删除报告类型
  async delType(id){
   await delTypeById({moldId:id})
   this.getReportType()
   },
  //点击确定按钮
     async btnok(){
       await this.$refs.editForm.validate()
       await editReportType(this.newtitle)
       this.getReportType()
      this.closeDia()
       this.$message.success('修改成功')
       this.newtitle.content=''
       this.newtitle.title=''
      },
          //点击×按钮退出
  closeDia(){
    this.isShow=false
     this.newtitle.title=''
       this.newtitle.content=''

  },
      //点击取消按钮
    btnout(){

      this.closeDia()
    },
  //点击新增弹框确定
refreshlist(){

  this.getReportType()
}
}
}
</script>

<style lang="less" scoped>
.subbtn{
 
 padding-right: 100px;
  margin-top: 70px;
}

.el-button{
width: 110px;
height: 40px;
background: #FFFFFF;
border-radius: 12px;
border: 2px solid #4A71AF;

font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #4A71AF;
line-height: 15
px;

}
.btnOK&.el-button {
width: 110px;
height: 40px;
background: #4A71AF!important;
border-radius: 12px;



font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
margin-right: 80px;
}
/deep/ .el-input{
  width: 60%;
  margin-right:120px;
   .el-input__inner{
   width: 300px;
height: 40px;
border-radius: 12px;
border: 2px solid #4A71AF;
  }
}
.disable /deep/ .el-input__inner{
  width: 300px;
height: 40px;
background: #F2F2F2;
border-radius: 12px;
border: 2px solid #E1E1E1;
}
/deep/ .el-form .el-form-item__label{
  margin-left: 120px;
  width: 60px;
height: 22px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #273352;
line-height: 22px;
letter-spacing: 1px;
 margin-top: 6px;

}
/deep/ .el-form .el-form-item{
  margin-bottom: 30px;
}

 /deep/ .el-dialog{
width: 796px;
height: 533px;
background: #FFFFFF;
border-radius: 51px;
}
/deep/ .el-dialog__title{
  width: 76px;
height: 22px;
font-size: 19px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #273352;
line-height: 22px;
}
/deep/ .el-dialog__close{
font-size: 18px;
color: #333303;
opacity: 0.4;
margin-top: 18px;
margin-right: 20px;
}
.header{
 height: 45px;
background: #F9F9F9;
 .el-col{
    font-size: 12px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #4A71AF;
  }
}

</style>