<template>
  <div>
    <!-- 标签部分 -->

    <el-row type="flex" align="middle" v-for="(item,i) in policy" :key="item.id">
      <el-col :span="1">类型{{i+1}} :</el-col>
      <el-col :span="6" align="middle">
        <input disabled :id="item.id" :class="ipt" v-model="item.title" placeholder="最多十字">
        <input disabled :id="item.id" :class="ipt" v-model="item.content" placeholder="最多十字">

      </el-col>
      <el-col :span="2" align="middle">
        <button class="editBtn" @click="edit(item.id)">编辑</button>
      </el-col>
      <el-col :span="2">
        <el-button class="del" @click="delBtn(item.id)">删除该类型</el-button>

      </el-col>
    </el-row>
    <!-- //添加新的类型 -->
    <el-row>
      <el-col :span="4">
        <el-button class="editBtn" @click="addType">+添加新的类型</el-button>
      </el-col>
    </el-row>
    <!-- 添加新报告类型弹框 -->
    <!-- TODO: -->
    <el-dialog title="添加新报告类型" :visible.sync="showAdd" class="addDialog" @close="closeDialog">
      <el-form ref="formDialog" :model="newreport" :rules="rules" label-width="145px">
        <el-form-item label="报告类型名称:" prop="title">
          <el-input v-model="newreport.title"></el-input>
        </el-form-item>
        <el-form-item label="报告类型描述:" prop="content">
          <el-input v-model="newreport.content"></el-input>
        </el-form-item>
        <!-- 确定和取消 -->
        <el-form-item class="subbtn">
          <el-button class="btnOK" @click="addReportType">确认</el-button>
          <el-button @click="btnout">取消退出</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { addReportType } from '@/api/report.js'
export default {
  props: {
    policy: Array
  },
  data () {
    var checktitle = (rule, value, callback) => {
      let flag = this.policy.some(item => item.title === value)
      if (flag) {
        callback(new Error('报告类型不能重复'))
      } else {
        callback()
      }
    };
    return {
      ipt: 'ipt',
      rules: {
        title: [{ required: true, message: '报告名称不能为空', trigger: 'blur' }, { validator: checktitle, trigger: 'blur' }],
        content: [{ required: true, message: '类型描述不能为空', trigger: 'blur' }]
      },
      showAdd: false,
      newreport: {
        content: '',
        title: '',

      }
    }

  },
  created () {

  },

  methods: {

    edit (id) {
      this.$emit('showDialog', id)
    },
    //点击添加操作
    addType () {
      this.showAdd = true
    },
    //点击x操作
    closeDialog () {
      this.$refs.formDialog.resetFields()

    },
    //点击取消退出
    btnout () {
      this.closeDialog()
      this.showAdd = false

    },
    //删除报告类型
    delBtn (id) {
      this.$confirm('此操作将永久删除该类型相关的报告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('delType', id)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


    //新增报告类型
    async addReportType () {
      await this.$refs.formDialog.validate()
      await addReportType(this.newreport)
      this.newreport.content = ''
      this.newreport.title = ''
      this.showAdd = false
      this.$message.success('添加成功')

      this.$emit('refreshlist')


    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 50px;
  .el-col:nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a71af;
    margin-right: 16px;
    margin-bottom: 20px;
  }
  .el-col:nth-child(2) .ipt {
    width: 375px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #c9c9c9;
    padding-left: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #292d31;
    margin-bottom: 10px;
  }

  //  .el-col:nth-child(2)  .iptt{
  //   width: 248px;
  // height: 40px;
  // background: #FFFFFF;
  // border-radius: 12px;
  // border: 1px solid #C9C9C9;
  // padding-left: 20px;
  // font-size: 14px;
  // font-family: PingFangSC-Medium, PingFang SC;
  // font-weight: 500;
  // color: #9F9F9F ;
  // }

  .editBtn {
    width: 130px;
    height: 40px;
    background: #4a71af;
    border-radius: 12px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    border: 0;
  }
  .del {
    width: 130px;
    height: 40px;
    background: #e35d5d;
    border-radius: 12px;
    border: 0;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}

.editBtn {
  width: 130px;
  height: 40px;
  background: #4a71af;
  border-radius: 12px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  border: 0;
}
.addDialog /deep/ .el-dialog {
  width: 796px;
  height: 445px;
  background: #ffffff;
  border-radius: 51px;
  padding-top: 20px;
  box-sizing: border-box;

  .el-form {
    margin-top: 30px;
    box-sizing: border-box;
  }
}
.subbtn {
  padding-right: 100px;
  margin-top: 70px;
  box-sizing: border-box;
}
.el-button {
  width: 110px;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #4a71af;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4a71af;
  line-height: 15px;
}
.btnOK&.el-button {
  width: 110px;
  height: 40px;
  background: #4a71af;
  border-radius: 12px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-right: 50px;
  box-sizing: border-box;
}
</style>